.dialogue_container {
  background: linear-gradient(to bottom, #fd749b, #281ac8);
  border-radius: 4px;
  display: inline-block;
  padding: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: "auto";
  outline: "none";
}

.dialogue_content {
  background: #1e0338;
  display: flex;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;
}