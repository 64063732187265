.content {
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  color: #1e0338;
  font-family: "AzoSans";
}

.containerLight {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background: #f2f2f2;
  flex-wrap: wrap;
}

.containerLight::-webkit-scrollbar,
.policy-container::-webkit-scrollbar {
  display: none;
}

.containerLight,
.policy-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.header {
  margin: 0;
  font-size: 18px !important;
  line-height: 30px;
  color: #1e0338;
  font-weight: 700;
  font-family: "AzoSans";
}

a {
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  font-family: "AzoSans";
}

br {
  content: "";
  margin: 8px;
  display: block;
}
