.list__content__light {
  color: #1e0338;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 29px;
}

.healthy-social-container::-webkit-scrollbar {
  display: none;
}
.healthy-social-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
