.paper-scroll::-webkit-scrollbar {
  width: 4px;
}
.paper-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.paper-scroll::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
}
