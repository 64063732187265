.terms-policy-dark-main-container {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.terms-policy-dark-main-container::-webkit-scrollbar {
  display: none;
}

.contentDark {
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  color: #fff;
  font-family: "AzoSans";
}

.container {
  max-width: 100%;
  flex-wrap: wrap;
}

.headerDark {
  margin: 0;
  font-size: 18px !important;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  font-family: "AzoSans";
}

a {
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  font-family: "AzoSans";
}

br {
  content: "";
  margin: 8px;
  display: block;
}
