body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "AzoSans", "Helvetica",
    "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1e0338;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: white;
  /* color of the scroll thumb */
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "AzoSans-Black";
  src: url("./assets/fonts/AzoSans/AzoSans-Black.ttf");
}

@font-face {
  font-family: "AzoSans-Bold";
  src: url("./assets/fonts/AzoSans/AzoSans-Bold.ttf");
}

@font-face {
  font-family: "AzoSans-Light";
  src: url("./assets/fonts/AzoSans/AzoSans-Light.ttf");
}

@font-face {
  font-family: "AzoSans-Medium";
  src: url("./assets/fonts/AzoSans/AzoSans-Medium.ttf");
}

@font-face {
  font-family: "AzoSans";
  src: url("./assets/fonts/AzoSans/AzoSans-Regular.ttf");
}

@font-face {
  font-family: "AzoSans-Thin";
  src: url("./assets/fonts/AzoSans/AzoSans-Thin.ttf");
}

.linear__border {
  padding: 24px 33px;
  border: 1px solid #615771;
  border-radius: 10px;
}
.linear__border_donut {
  border: 1px solid #615771;
  border-radius: 10px;
}
.linear__border__pie {
  padding: 24px 10px;
  border: 1px solid #615771;
  border-radius: 10px;
}
