.aboutContentDark {
  font-size: 14px !important;
  line-height: 30px;
  margin-top: 20px;
  color: #fff;
  font-family: "AzoSans";
}
.aboutContentLight {
  font-size: 14px !important;
  line-height: 30px;
  font-weight: 400;
  margin-top: 20px;
  color: #595959;
  font-family: "AzoSans";
}

.about-allday-container::-webkit-scrollbar {
  display: none;
}
.about-allday-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.about-allday-dark-container {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.about-allday-dark-container::-webkit-scrollbar {
  display: none;
}
